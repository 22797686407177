import React, { useContext, useState } from 'react';
import { AuthContext } from '@leuven2030/framework/Auth/Auth';
import { AuthMessage } from '@leuven2030/framework/Auth/AuthMessage';
import { AuthSigninSocial } from '@leuven2030/framework/Auth/AuthSigninSocial';
import { AuthState, ChallengeName } from '@leuven2030/framework/Auth/AuthTypes';
import { AuthTitle } from '@leuven2030/framework/Auth/AuthTitle';
import isEmpty from 'lodash/isEmpty';
import { Auth } from '@aws-amplify/auth';
import 'twin.macro';
import UserContext from '@leuven2030/framework/User/UserContext';
import Button from '@leuven2030/framework/Button/Button';
import Form from '@leuven2030/framework/Form/Form';
import FieldEmail from '@leuven2030/framework/Field/FieldEmail';
import FieldPassword from '@leuven2030/framework/Field/FieldPassword';
import BrandLogo from '@leuven2030/framework/Brand/BrandLogo';

export const AuthSignin = () => {
  const [loading, setLoading] = useState(false);
  const { message, setErrorMessage, setUser, setAuthState } = useContext(
    AuthContext
  );
  const socialLoginEnabled = false;
  const { reloadUserInfo } = useContext(UserContext);
  const initialValues = {
    email: '',
    password: ''
  };

  return (
    <div>
      <div tw="space-y-6">
        <div tw="bg-gray-100 p-3 space-y-3">
          <BrandLogo tw="h-10 w-auto mx-auto" />
          <div>
            <h2 tw="uppercase text-base text-primary-600">Single sign-on</h2>
            <p tw="text-xs text-gray-500">
              With this account you can access all of Leuven2030's digital
              services
            </p>
            <div tw="flex justify-end">
              <span tw="text-primary-600">read more</span>
            </div>
          </div>
        </div>
      </div>
      {socialLoginEnabled && !message && (
        <div>
          <div>
            <p tw="text-sm leading-5 font-medium text-gray-700">Sign in with</p>

            <AuthSigninSocial />
          </div>

          <div tw="mt-6 relative">
            <div tw="absolute inset-0 flex items-center">
              <div tw="w-full border-t border-gray-300" />
            </div>
            <div tw="relative flex justify-center text-sm leading-5">
              <span tw="px-2 bg-white text-gray-500">Or continue with</span>
            </div>
          </div>
        </div>
      )}

      <div tw="mt-6">
        <Form
          schemaName="UserCredentials"
          onSubmit={signIn}
          initialValues={initialValues}
          tw="flex-col space-y-6"
        >
          <FieldEmail />
          <FieldPassword />
          <div tw="mt-6 flex items-center justify-end">
            <div tw="text-sm leading-5">
              <a
                href="#"
                tw="font-medium text-primary-600 hover:text-primary-500 focus:outline-none focus:underline transition ease-in-out duration-150"
              >
                Forgot your password?
              </a>
            </div>
          </div>
          <AuthMessage />
          <Button
            variant="contained"
            loading={loading}
            tw="mt-6 w-full"
            color="primary"
            type="submit"
          >
            Sign in
          </Button>
        </Form>
      </div>

      <div tw="flex items-center mt-8 justify-end py-6">
        <span tw="text-xs mr-3">{`Don't have an account?`}</span>
        <Button
          variant="contained"
          size="small"
          onClick={() => setAuthState(AuthState.SignUp)}
        >
          Sign up
        </Button>
      </div>
    </div>
  );

  async function signIn(credentials: typeof initialValues) {
    const { email, password } = credentials;
    setLoading(true);
    setUser(credentials);
    const response = await Auth.signIn(email, password).catch((error) => {
      setErrorMessage(error.message);
      if (error.code === 'UserNotConfirmedException') {
        setAuthState(AuthState.ConfirmSignUp);
      } else if (error.code === 'PasswordResetRequiredException') {
        setAuthState(AuthState.ForgotPassword);
      }
      return { error };
    });
    if (!response.error) {
      switch (response.challengeName) {
        case ChallengeName.SMSMFA:
        case ChallengeName.SoftwareTokenMFA:
          setAuthState(AuthState.ConfirmSignIn);
          break;
        case ChallengeName.NewPasswordRequired:
          setAuthState(AuthState.ResetPassword);
        case ChallengeName.MFASetup:
        case ChallengeName.TOTPSetup:
          setAuthState(AuthState.TOTPSetup);
        default:
          checkContact(response);
          break;
      }
    }

    setLoading(false);
  }

  function checkContact(user) {
    Auth.verifiedContact(user).then((data) => {
      if (isEmpty(data.verified)) {
        user = Object.assign(user, data);
        setAuthState(AuthState.VerifyContact);
      } else {
        reloadUserInfo();
      }
    });
  }
};

export default AuthSignin;
