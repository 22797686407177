import { Auth } from '@aws-amplify/auth';
import { AuthOptions } from '@aws-amplify/auth/src/types';
import ServerEnv from '@leuven2030/framework/Server/ServerEnv';

const AuthAmplify = () => {
  const options: AuthOptions = {
    userPoolId: process.env.COGNITO_POOL_ID,
    userPoolWebClientId: process.env.COGNITO_CLIENT_ID,
    region: process.env.AWS_REGION,
    authenticationFlowType: 'USER_SRP_AUTH',
    cookieStorage: {
      domain: ServerEnv.domain,
      path: '/',
      // expires: 365,
      secure: !ServerEnv.isDev
    }
  };
  Auth.configure(options);
};

export default AuthAmplify;
