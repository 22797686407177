import { AuthContext } from '@leuven2030/framework/Auth/Auth';
import React, { FC, useContext } from 'react';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import 'twin.macro';

export type AuthMessageType = {
  title?: string;
  text: string;
  severity: AlertProps['severity'];
} | null;

export type AuthMessageProps = {
  initialValue?: AuthMessageType;
};

export const AuthMessage: FC<AuthMessageProps> = ({ initialValue }) => {
  const { message, setMessage } = useContext(AuthContext);
  const alertsProps = message || initialValue;
  return (
    <>
      {alertsProps && (
        <Alert
          severity={alertsProps.severity}
          tw="mt-3"
          title={alertsProps.title}
          onClose={() => {
            setMessage(null);
          }}
        >
          {alertsProps.text}
        </Alert>
      )}
    </>
  );
};
