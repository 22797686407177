import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState
} from 'react';
import 'twin.macro';
import AuthAmplify from '@leuven2030/framework/Auth/AuthAmplify';
import { AuthConfirmSignup } from '@leuven2030/framework/Auth/AuthConfirmSignup';
import { AuthSignin } from '@leuven2030/framework/Auth/AuthSignin';
import { AuthSignedIn } from '@leuven2030/framework/Auth/AuthSignedIn';
import { AuthSignup } from '@leuven2030/framework/Auth/AuthSignup';
import { AuthState } from '@leuven2030/framework/Auth/AuthTypes';
import { AuthMessageType } from '@leuven2030/framework/Auth/AuthMessage';
import UserContext from '@leuven2030/framework/User/UserContext';

type AuthContextProps = {
  message?: AuthMessageType;
  setMessage?: Dispatch<SetStateAction<AuthMessageType>>;
  authState?: AuthState;
  setAuthState?: Dispatch<any>;
  setErrorMessage?: Dispatch<any>;
  setSuccessMessage?: Dispatch<any>;
  user?: any;
  setUser?: Dispatch<any>;
};

export type AuthProps = {
  initialAuthState?: AuthState.SignIn | AuthState.SignUp;
};

export const AuthContext = createContext<AuthContextProps>({});

AuthAmplify();

export const Auth: FC<AuthProps> = ({
  initialAuthState = AuthState.SignIn
}) => {
  const [authState, setAuthState] = useState<AuthState>(initialAuthState);
  const [message, setMessage] = useState<AuthMessageType>(null);
  const [user, setUser] = useState(null);
  const { isLoggedin } = useContext(UserContext);

  // const [error, setError] = useState(null);
  // const [email, setEmail] = useState<string>(null);

  const context: AuthContextProps = {
    message,
    setMessage,
    setErrorMessage: (text: string) => {
      setMessage({
        text,
        severity: 'error'
      });
    },
    setSuccessMessage: (text: string) => {
      setMessage({
        text,
        severity: 'success'
      });
    },
    authState,
    setAuthState: (value: AuthState) => {
      setMessage(null);
      setAuthState(value);
    },
    user,
    setUser
  };

  if (isLoggedin) {
    return <AuthSignedIn />;
  }

  return (
    <AuthContext.Provider value={context}>
      <div tw="space-y-3 max-w-sm p-6 mx-auto">
        {renderAuthComponent(authState)}
      </div>
    </AuthContext.Provider>
  );
};

const renderAuthComponent = (authState: AuthState) => {
  switch (authState) {
    case AuthState.SignIn:
      return <AuthSignin />;
    case AuthState.ConfirmSignIn:
      return <div>confirm Signin</div>;
    case AuthState.SignUp:
      return <AuthSignup />;
    case AuthState.ConfirmSignUp:
      return <AuthConfirmSignup />;
    case AuthState.ForgotPassword:
      return <div>ForgotPassword</div>;
    case AuthState.ResetPassword:
      return <div>ResetPassword</div>;
    case AuthState.VerifyContact:
      return <div>VerifyContact</div>;
    default:
      throw new Error(`Unhandled auth state: ${authState}`);
  }
};

export default Auth;
