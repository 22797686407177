import { Auth } from '@aws-amplify/auth';
import React, { useContext, useState } from 'react';
import { AuthContext } from '@leuven2030/framework/Auth/Auth';
import { AuthState } from '@leuven2030/framework/Auth/AuthTypes';
import FieldText from '@leuven2030/framework/Field/FieldText';
import Button from '@leuven2030/framework/Button/Button';
import UserContext from '@leuven2030/framework/User/UserContext';
import Form from '@leuven2030/framework/Form/Form';

export const AuthConfirmSignup = () => {
  const [loading, setLoading] = useState(false);
  const {
    setErrorMessage,
    setSuccessMessage,
    setAuthState,
    user: userData
  } = useContext(AuthContext);
  const { reloadUserInfo } = useContext(UserContext);

  const initialValues = {
    code: ''
  };

  return (
    <div className="space-y-10">
      <div className="mt-6">
        <Form onSubmit={confirmSignUp} initialValues={initialValues}>
          <FieldText title="Verification code" name="code" type="text" />

          <div className="mt-6">
            <span className="block w-full rounded-md shadow-sm">
              <Button color="primary" type="submit" loading={loading}>
                Confirm
              </Button>
            </span>
          </div>
        </Form>
      </div>
      <div className="flex items-center space-x-3 justify-between">
        <Button
          onClick={() => {
            setAuthState(AuthState.SignIn);
          }}
        >
          Back to sign in
        </Button>
        <Button variant="contained" onClick={resendConfirmCode}>
          Resend code
        </Button>
      </div>
    </div>
  );

  async function confirmSignUp(form: typeof initialValues) {
    setLoading(true);

    const response = await Auth.confirmSignUp(userData.email, form.code).catch(
      (error) => {
        setErrorMessage(error.message);
        return {
          error
        };
      }
    );
    if (!response.error && userData.email && userData.password) {
      await Auth.signIn(userData.email, userData.password);
      await reloadUserInfo();
    }
    setLoading(false);
  }

  async function resendConfirmCode() {
    if (!userData.email) throw new Error('Username can not be empty');
    await Auth.resendSignUp(userData.email).catch((error) => {
      setErrorMessage(error.message);
    });
    setAuthState(AuthState.ConfirmSignUp);
    setSuccessMessage(
      `The verification code was sent to your email: ${userData.email}`
    );
  }
};

export default AuthConfirmSignup;
