import FieldText, {
  FieldTextProps
} from '@leuven2030/framework/Field/FieldText';
import React, { FC } from 'react';

const FieldPassword: FC<FieldTextProps> = ({ title, name, ...rest }) => {
  return (
    <FieldText
      {...rest}
      name={name || 'password'}
      type="password"
      title={title || 'Password'}
    />
  );
};

export default FieldPassword;
