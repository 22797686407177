export enum AuthState {
  SignUp = 'signup',
  SignOut = 'signout',
  SignIn = 'signin',
  Loading = 'loading',
  SignedOut = 'signedout',
  SigningUp = 'signingup',
  ConfirmSignUp = 'confirmSignUp',
  confirmingSignUpCustomFlow = 'confirmsignupcustomflow',
  ConfirmSignIn = 'confirmSignIn',
  confirmingSignInCustomFlow = 'confirmingsignincustomflow',
  VerifyingAttributes = 'verifyingattributes',
  ForgotPassword = 'forgotpassword',
  ResetPassword = 'resettingpassword',
  SettingMFA = 'settingMFA',
  TOTPSetup = 'TOTPSetup',
  CustomConfirmSignIn = 'customConfirmSignIn',
  VerifyContact = 'verifyContact'
}

export enum ChallengeName {
  TOTPSetup = 'TOTPSetup',
  SoftwareTokenMFA = 'SOFTWARE_TOKEN_MFA',
  SMSMFA = 'SMS_MFA',
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
  MFASetup = 'MFA_SETUP',
  CustomChallenge = 'CUSTOM_CHALLENGE'
}
