import { AuthTitle } from '@leuven2030/framework/Auth/AuthTitle';
import React, { useContext } from 'react';
import UserContext from '@leuven2030/framework/User/UserContext';
import Button from '@leuven2030/framework/Button/Button';
import 'twin.macro';

export const AuthSignedIn = () => {
  const { signOut } = useContext(UserContext);

  return (
    <div tw="space-y-3">
      <AuthTitle title="Already signed in" />
      <div tw="space-x-3">
        <Button href="/">Explore</Button>
        <Button color="secondary" onClick={signOut}>
          Sign Out
        </Button>
      </div>
    </div>
  );
};
